<template>
<div class="body">
    <!-- 优惠券状态通知 -->
    <!-- <div class="w-100">
        <el-form-item label="活动名称：">
            <el-input v-model="" placeholder="请输入本次活动名称！"></el-input>
        </el-form-item>
    </div> -->
    <h4>一、选择消息模板类型并编辑</h4>
    <!-- 选择模板类型 -->
    <div class="mouldBox">
        <span>模板：</span>
        <el-select v-model="templateIndex" placeholder="请选择消息推送模板！">
            <el-option
                v-for="item in mouldList"
                :key="item.id"
                :label="item.label"
                :value="item.value">
            </el-option>
        </el-select>
    </div>
    
    <!-- 模板内容填写 -->
    <el-form ref="formCase" :model="formCase" label-width="120px" :rules="rules">
        <div v-if="templateIndex == '666' " class="form">
            <el-form-item label="优惠券名称：" class="formItemOne" prop="name0"><!-- 优惠券状态通知 -->
                <el-input v-model="formCase.name0" class="w-100" maxlength="20" show-word-limit clearable></el-input>
            </el-form-item>
            <el-form-item label="优惠券状态：" class="formItemOne" prop="name1"><!-- 优惠券状态通知 -->
                <el-input v-model="formCase.name1" class="w-100"></el-input>
            </el-form-item>
            <el-form-item label="生效时间：" class="formItemOne" prop="name2"><!-- 优惠券状态通知 -->
                <!-- <el-input v-model="formCase.name3"></el-input> -->
                <el-date-picker
                    v-model="formCase.name2"
                    type="date"
                    format="yyyy年MM月dd日"
                    value-format="yyyy年MM月dd日"
                    placeholder="选择日期"
                    class="w-100">
                </el-date-picker>
            </el-form-item>
            <el-form-item label="失效时间：" class="formItemOne" prop="name3"><!-- 优惠券状态通知 -->
                <!-- <el-input v-model="formCase.name4"></el-input> -->
                <el-date-picker
                    v-model="formCase.name3"
                    type="date"
                    format="yyyy年MM月dd日"
                    value-format="yyyy年MM月dd日"
                    placeholder="选择日期"
                    class="w-100">
                </el-date-picker>
            </el-form-item>
            <el-form-item label="使用说明：" class="formItemOne" prop="name4"><!-- 优惠券状态通知 -->
                <el-input v-model="formCase.name4" class="w-100" maxlength="20" show-word-limit clearable></el-input>
            </el-form-item>
        </div>
        <!-- 分割线------------------------------------------>
        <!-- 活动通知 -->
        <div v-else class="form">
            <el-form-item label="活动名：" class="formItemOne" prop="name0">
                <el-input v-model="formCase.name0" class="w-100" maxlength="20" show-word-limit clearable></el-input>
            </el-form-item>
            <el-form-item label="活动内容：" class="formItemOne" prop="name1">
                <el-input v-model="formCase.name1" class="w-100" maxlength="20" show-word-limit clearable></el-input>
            </el-form-item>
            <el-form-item label="活动时间：" class="formItemOne" prop="name2">
                <el-date-picker
                    v-model="formCase.name2"
                    type="daterange"
                    range-separator="至"
                    format="yyyy年MM月dd日"
                    value-format="yyyy年MM月dd日"
                    start-placeholder="开始日期"
                    end-placeholder="结束日期"
                    class="w-100">
                </el-date-picker>
            </el-form-item>
            <el-form-item label="温馨提示：" class="formItemOne" prop="name3">
                <el-input v-model="formCase.name3" class="w-100" maxlength="20" show-word-limit clearable></el-input>
            </el-form-item>
            <el-form-item label="活动截止：" class="formItemOne" prop="name4" >
                <el-date-picker
                    v-model="formCase.name4"
                    type="date"
                    format="yyyy年MM月dd日"
                    value-format="yyyy年MM月dd日"
                    placeholder="选择日期"
                    class="w-100">
                </el-date-picker>
            </el-form-item>
        </div>
        <!-- 分割线---------------------------------------- -->
        <!-- 订单状态变更提醒 -->
        <!-- <el-form-item label="活动名：">
            <el-input v-model="form.name"></el-input>
        </el-form-item>
        <el-form-item label="活动内容：">
            <el-input v-model="form.name"></el-input>
        </el-form-item>
        <el-form-item label="活动时间：">
            <el-input v-model="form.name"></el-input>
        </el-form-item>
        <el-form-item label="温馨提示：">
            <el-input v-model="form.name"></el-input>
        </el-form-item>
        <el-form-item label="活动截止：">
            <el-input v-model="form.name"></el-input>
        </el-form-item> -->
    </el-form>
    <!-- 投放会员筛选条件 -->
    <h4>二、推送订阅消息的会员筛选条件和跳转页面参数<span style="font-size:12px;margin-left:24px;color:grey">满足昵称/手机、会员等级、积分范围三个条件的会员将会收到本订阅消息，不选默认全部会员</span></h4>
    <el-form ref="formVip" :model="formVip" label-width="120px" class="form" :rules="formVipRules">
        <el-form-item label="昵称/手机号：" class="formItem">
            <el-tooltip class="item" effect="dark" content="精准投放特定用户，一般不要填写" placement="top">
                <el-input v-model="formVip.phoneList" clearable></el-input>
            </el-tooltip>
        </el-form-item>
        <el-form-item label="会员等级：" class="formItem">
            <el-tooltip class="item" effect="dark" content="不填默认全等级会员" placement="top">
                <el-select
                    v-model="formVip.cdmtype"
                    filterable
                    clearable
                    placeholder="请选择会员等级">
                        <el-option
                            v-for="item in vipLevelList"
                            :key="item.id"
                            :label="item.name"
                            :value="item.value">
                        </el-option>
                </el-select>
            </el-tooltip>
        </el-form-item>
        <el-form-item label="积分范围："  class="formItem">
            <div class="integralBigBox">
                <el-tooltip class="integralBox" effect="dark" content="不填默认从最小积分用户开始筛选" placement="top">
                    <el-input v-model="formVip.startTotalMemberPoints" placeholder="最低积分！" clearable></el-input>
                </el-tooltip>
                <span>--</span>
                <el-tooltip class="integralBox" effect="dark" content="不填默认筛选到最大积分用户" placement="top">
                    <el-input v-model="formVip.endTotalMemberPoints" placeholder="最高积分！" clearable></el-input>
                </el-tooltip>
            </div>
        </el-form-item>
        <el-form-item label="跳转路径：" class="formItem" prop="page" >
            <el-tooltip class="item" effect="dark" content="下拉列表为参考路径，可手动输入路径！" placement="top">
                <el-select
                    v-model="formVip.page"
                    filterable
                    clearable
                    allow-create
                    default-first-option
                    placeholder="请选择或输入地址">
                        <el-option
                            v-for="item in pagesList"
                            :key="item.id"
                            :label="item.name"
                            :value="item.value">
                        </el-option>
                </el-select>
            </el-tooltip>
        </el-form-item>
        <el-form-item label="小程序类型：" class="formItem" prop="miniprogramState" >
            <el-tooltip class="item" effect="dark" content="非管理员强制为正式版！" placement="top">
                <el-select
                    v-model="formVip.miniprogramState"
                    filterable
                    clearable
                    placeholder="请选择小程序类型">
                        <el-option
                            v-for="item in miniprogramStateList"
                            :key="item.id"
                            :label="item.name"
                            :value="item.value">
                        </el-option>
                </el-select>
            </el-tooltip>
        </el-form-item>
        <el-form-item label="发送状态：" class="formItem" prop="sendStatus" >
            <!-- <el-tooltip class="item" effect="dark" content="非管理员强制为正式版！" placement="top"> -->
                <el-select
                    v-model="formVip.sendStatus"
                    filterable
                    clearable
                    placeholder="请选择发送状态">
                        <el-option
                            v-for="item in sendStatusList"
                            :key="item.value"
                            :label="item.name"
                            :value="item.value">
                        </el-option>
                </el-select>
            <!-- </el-tooltip> -->
        </el-form-item>
        <el-form-item label="发送时间：" class="formItem" prop="sendTime" >
            <!-- <el-tooltip class="item" effect="dark" content="非管理员强制为正式版！" placement="top"> -->
                <el-date-picker
                    v-model="formVip.sendTime"
                    format="yyyy年MM月dd日 HH:mm:ss"
                    value-format="yyyy年MM月dd日 HH:mm:ss"
                    type="datetime"
                    placeholder="选择日期时间">
                </el-date-picker>
            <!-- </el-tooltip> -->
        </el-form-item>
    </el-form>
    <!-- 确认提交按钮 -->
    <div class="submitButton">
        <el-button type="primary" class="submitButton" @click="submitMould">确认</el-button>
    </div>
</div>
</template>
<script>
import {getSubscribeList,addNewSubscribe} from "@/api/config_center/miniprogram_subscribe";//专区活动列表API
export default {
    name:'subscribeNews',
    data() {
        return{
            templateIndex:'11116',//模板识别标识，当前有三个模板
            mouldList:[
                {
                label:'活动通知',//模板名称(与微信后台名称一致)
                value:'666',//模板编号(与微信后台名称一致)
                id:0,
                templateId:'mKxhd10VLGw-qO85yKf8N7yYgzROlSf9wKEKwvFhkFc'
                },
                {
                label:'优惠券状态通知',//模板名称(与微信后台名称一致)
                value:'11116',//模板编号(与微信后台名称一致)
                id:1,
                templateId:'co7Uf_-CosFnRKlJFmVHQlX7oXaJFiEVJOJK-7PWdiU'
                },
            ],
            activityName:'',
            // 模板类型多，统一按顺序获取前端数据，提交时存放到submitData
            formCase:{
                name0:'',
                name1:'',
                name2:'',
                name3:'',
                name4:'',
            },
            // 提交保存模板的数据
            submitData11116:[
                {name:'thing1',value:''},
                {name:'phrase2',value:''},
                {name:'time3',value:''},
                {name:'time4',value:''},
                {name:'thing5',value:''},
            ],
            submitData666:[
                {name:'thing1',value:''},
                {name:'thing4',value:''},
                {name:'time6',value:''},
                {name:'thing5',value:''},
                {name:'date3',value:''},
            ],
            // formVip 订阅消息推送对象筛选条件
            formVip:{
                phoneList:'',//手机号，会员号
                startTotalMemberPoints:'',//起始积分范围
                endTotalMemberPoints:'',//结束积分范围
                cdmtype:'',//会员等级ID，传id，18金卡，20钻卡，23铂金卡
                miniprogramState:'formal',//跳转小程序类型
                page:'',//跳转到额页面路径
                sendStatus:'',//发送状态
                sendTime:'',//发送时间
            },
            rules: {
                name0: [
                { required: true, message: '请编辑相关内容', trigger: 'blur' },
                // { min: 2, max: 20, message: '长度在 2 到 20 个字符', trigger: 'blur' }
                ],
                name1: [
                { required: true, message: '请编辑相关内容', trigger: 'blur' },
                // { min: 2, max: 20, message: '长度在 2 到 20 个字符', trigger: 'blur' }
                ],
                name2: [
                { required: true, message: '请编辑相关内容', trigger: 'blur' },
                // { min: 2, max: 30, message: '长度在 2 到 25 个字符', trigger: 'blur' }
                ],
                name3: [
                { required: true, message: '请编辑相关内容', trigger: 'blur' },
                // { min: 2, max: 20, message: '长度在 2 到 20 个字符', trigger: 'blur' }
                ],
                name4: [
                { required: true, message: '请编辑相关内容', trigger: 'blur' },
                // { min: 2, max: 20, message: '长度在 2 到 20 个字符', trigger: 'blur' }
                ],
            },
            formVipRules:{
                page: [
                { required: true, message: '请编辑相关内容', trigger: 'blur' },
                ],
                miniprogramState: [
                { required: true, message: '请编辑相关内容', trigger: 'blur' },
                ],
                sendStatus: [
                { required: true, message: '请编辑相关内容', trigger: 'blur' },
                ],
                sendTime: [
                { required: true, message: '请编辑相关内容', trigger: 'blur' },
                ],
            },
            // 参考跳转路径列表
            pagesList:[
                {
                    id:0,
                    name:'百货首页',
                    value:'pages/index/index'
                },
                {
                    id:1,
                    name:'商超首页',
                    value:'pages/index/shangchao'
                },
                {
                    id:2,
                    name:'卡券中心',
                    value:'pages/bh/myCoupons/myCoupons'
                },
            ],
            miniprogramStateList:[
                {
                    id:0,
                    name:'正式版',
                    value:'formal',
                },
                {
                    id:1,
                    name:'开发版',
                    value:'developer',
                },
                {
                    id:2,
                    name:'体验版',
                    value:'trial',
                },
            ],
            sendStatusList:[
                {
                    name:'待发送',
                    value:1,
                },
                {
                    name:'已发送',
                    value:2,
                },
                {
                    name:'发送成功',
                    value:3,
                },
                {
                    name:'发送失败',
                    value:4,
                },
            ],
            vipLevelList:[
                {id:0,name:'黄金会员',value:18},
                {id:1,name:'铂金会员',value:23},
                {id:2,name:'钻卡会员',value:20},
            ]
        }
    },
    mounted(){
    },
    methods:{
        submitMould(){
            let params = {}
            console.log(this.formVip,this.formCase)
            // let params = {...this.formVip,...this.formCase}
            console.log(params)
            for(let i = 0;i<this.mouldList.length;i++){
                if(this.templateIndex == this.mouldList[i].value){
                    params.templateId = this.mouldList[i].templateId
                }
            }
            if(this.templateIndex == '11116'){
                for(let i = 0 ;i<this.submitData11116.length;i++){
                    this.submitData11116[i].value = this.formCase['name'+i]
                }
                this.submitData11116[2].value = this.submitData11116[2].value[0] + '-'+this.submitData11116[2].value[1]
                console.log(this.submitData11116)
                params.data = JSON.stringify(this.submitData11116)
            }else{
                for(let i = 0 ;i<this.submitData666.length;i++){
                    this.submitData666[i].value = this.formCase['name'+i]
                }
                console.log(this.submitData666)
                params.data = JSON.stringify(this.submitData666)
            }
            params = {...params,...this.formVip}
            console.log(params)
            addNewSubscribe(params).then(res=>{
                console.log(res)
            })
        },
        getList(){
            let params = {
                pageNo:1,
                pageSize:10,
            }
            getSubscribeList(params).then(res=>{
                console.log(res)
            })
        },

    },
}
</script>
<style lang='scss' scoped>
.body{
    width: 95%;
    margin: 0 auto;
}
.mouldBox{
    width: 95%;
    margin: 24px auto;
}
.form{
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
}
.formItem{
    width: 300px;
}
.formItemOne{
    width: 400px;
}
.integralBigBox{
    display: flex;
    justify-content: space-around;
    height:40px
}
.integralBox{
    width: 100px;
    height:40px
}
.submitButton{
    margin: 0 auto;
    width: 100px;
}
.w-100{
    width: 100%;
    
}
</style>